<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>{{ $t("URL settings") }}</v-card-title>
      <v-card-text>
        <h3 class="mb-3 mt-3">Url</h3>
        <v-row no-gutters>
          <v-col xs="12" sm="2" lg="1">
            <v-select
              :items="pageUrls"
              v-model="pageDefaultUrl"
              single-line
              class="pageElementLanguageSelect"
            ></v-select>
          </v-col>
          <v-col xs="12" sm="10" lg="11">
            <template
              v-for="(translatedValue, translatedValueIndex) in page.Title"
            >
              <template v-if="pageDefaultUrl == translatedValueIndex">
                <input
                  :key="translatedValueIndex"
                  class="page-detail textInput mt-2"
                  v-model="page.Url[translatedValueIndex]"
                  type="text"
                />
              </template>
            </template>
          </v-col>
        </v-row>

        <h3 class="mb-3 mt-3">{{ $t("Unique name") }}</h3>
        <v-row no-gutters>
          <v-col xs="12" sm="4">
            <input
              class="page-detail textInput mb-4"
              v-model="page.UniqueName"
              type="text"
            />
          </v-col>
          <v-spacer></v-spacer>
        </v-row>

        <h3 class="mb-3 mt-3">{{ $t("Synchronization URLs") }}</h3>
        <v-row no-gutters class="mb-4">
          <v-col class="px-0" cols="8">
            <input
              class="page-detail textInput"
              v-model="page.SynchronizationPutUrl"
              placeholder="Put URL"
              type="text"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-4" v-if="page.RepeatingContent">
          <v-col cols="8">
            <input
              class="page-detail textInput"
              v-model="page.SynchronizationBulkPutUrl"
              placeholder="Bulk put URL"
              type="text"
            />
          </v-col>
        </v-row>
        <v-row no-gutters v-if="page.RepeatingContent">
          <v-col cols="8">
            <input
              class="page-detail textInput mb-4"
              v-model="page.SynchronizationDeleteUrl"
              placeholder="Delete URL"
              type="text"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: [Array, Object],
    },
    pageDefaultUrlProps: {},
    pageUrlsProps: {},
  },
  data() {
    return {
      pageUrls: this.pageUrlsProps,
      pageDefaultUrl: this.pageDefaultUrlProps,
    };
  },
};
</script>

<style>
</style>